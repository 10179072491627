import { getColorScheme } from '@/hooks/useColorScheme'
import { breakpoints } from '@/styles/breakpoints'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { CMSRegionTeam } from '@grandstand/presentation-models'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import styled from 'styled-components'

const TeamLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--logo-size);
  max-width: var(--logo-size);
  height: var(--logo-size);
  max-height: var(--logo-size);
  border-radius: 50%;
  background-color: var(--surface);
  overflow: hidden;
`

const TeamInfoContainerDiv = styled.div`
  display: flex;
  gap: 8px;
  width: 230px;
  height: 56px;
  align-items: center;
  margin-bottom: 4px;
`
const TeamInfoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  max-width: 56px;
  height: 56px;
  max-height: 56px;
  border-radius: 50%;
  background-color: var(--surface);
  overflow: hidden;
`

const AuthTeamInfoContainerDiv = styled.div`
  display: flex;
  align-items: center;
`
const AuthTeamName = styled.p`
  display: none;
  @media (max-width: 768px) {
    display: inline;
    color: var(--on-background);
    font-size: 16px;
    line-height: 19px;
  }
`

const TeamLogo = styled(Image)`
  margin-right: 10px;
  margin-left: 10px;
`

const TeamDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const TeamName = styled.p`
  color: var(--on-background);
  font-size: 16px;
  line-height: 19px;
`
const TeamMeta = styled.p`
  font-size: 12px;
  line-height: 17px;
`

// desktop-first
const AvailableTeamsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  max-height: auto;
  margin-left: auto;
  ${breakpoints.down('tablet')} {
    margin-left: 0;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    margin-top: 32px;
  }
  ${breakpoints.down('mobile')} {
    grid-template-columns: 1fr;
    gap: 6px;
    max-height: unset;
  }
`

const TeamsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  @media (max-width: 768px) {
    justify-content: initial;
  }
`

const LogoImageBorder = styled.div`
  border-radius: 50%;
  border-width: 2px;
  border-color: var(--divider-canvas);
  border-style: solid;
`

const LogoImageWrapper = styled.div`
  border-radius: 50%;
  border-color: var(--canvas);
  border-width: 2px;
  border-style: solid;
  padding: 24px;
  background-color: var(--surface);
  position: relative;
`

const LogoImage = styled(Image)`
  img {
    margin-left: 40px;
    border-radius: 50%;
    background-color: var(--surface);
    @media (max-width: 1042px) {
      display: none;
    }
  }
`
const IconWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`

const TeamInfo = ({ team, isLoggedIn, navList }: { team: CMSRegionTeam; isLoggedIn?: boolean; navList?: boolean }) => {
  const colorScheme = getColorScheme()
  const userService = useContext(UserServiceContext)

  const { short_name, slug, light_image } = team

  return (
    <>
      {isLoggedIn ? (
        <AuthTeamInfoContainerDiv>
          {userService.isInFavorites(team.sport_radar_id) ? (
            <LogoImageBorder key={team.sport_radar_id}>
              <LogoImageWrapper>
                <LogoImage src={team.light_image} alt={team.short_name} width={32} height={32} />
                <IconWrapper>
                  <GrandstandIcon icon="favorite_active" width="15px" />
                </IconWrapper>
              </LogoImageWrapper>
            </LogoImageBorder>
          ) : (
            <LogoImageWrapper key={team.sport_radar_id}>
              <LogoImage src={team.light_image} alt={team.short_name} width={32} height={32} />
            </LogoImageWrapper>
          )}
          <AuthTeamName>{team.short_name}</AuthTeamName>
        </AuthTeamInfoContainerDiv>
      ) : navList ? (
        <LogoImageWrapper key={team.sport_radar_id}>
          <LogoImage src={team.light_image} alt={`${team.short_name}`} width={32} height={32} />
        </LogoImageWrapper>
      ) : (
        <TeamInfoContainerDiv>
          <TeamInfoLink
            style={{ borderRadius: '2em', backgroundColor: '#fff' }}
            href={`/teams/${slug}`}
            title={`${short_name ?? ''} Team Hub`}
          >
            <TeamLogo src={team.light_image} height={43} width={43} alt="" />
          </TeamInfoLink>
          <TeamDetails>
            <TeamName>{team.short_name}</TeamName>
            <TeamMeta>
              {team.league.toUpperCase()} {team.DTCavailable ? '' : '• TV Provider Only'}
            </TeamMeta>
          </TeamDetails>
        </TeamInfoContainerDiv>
      )}
    </>
  )
}

const AvailableTeams = ({ teams, navList }: { teams: CMSRegionTeam[]; navList?: boolean }) => {
  const userService = useContext(UserServiceContext)
  return (
    <>
      {userService.isLoggedIn || navList ? (
        <TeamsRow>
          {teams.map((team, i) => (
            <TeamInfo key={i} team={team} isLoggedIn={userService?.isLoggedIn} navList={navList} />
          ))}
        </TeamsRow>
      ) : (
        <AvailableTeamsContainer>
          {teams.map((team, i) => (
            <TeamInfo key={i} team={team} navList={navList} />
          ))}
        </AvailableTeamsContainer>
      )}
    </>
  )
}

export default AvailableTeams
