const refreshHoursBeforeExpiration = 4
const refreshSecondsBeforeExpiration = 60 * 60 * refreshHoursBeforeExpiration

export const calculateRefreshTimestamp = (expirationTs: number, debug = false): number => {
  if (debug) {
    // Modified refresh time of 60s after issuance.  For debugging only
    // XXX: This debug tactic does not work with anonymous users.  You will see multiple refresh calls when not logged in.
    // For some reason, anon tokens do not get regularly updated exp and are unpredicatable to spoof.
    return new Date(0).setUTCSeconds(expirationTs - 60 * 60 * 24 + 60)
  } else {
    return new Date(0).setUTCSeconds(expirationTs - refreshSecondsBeforeExpiration)
  }
}
