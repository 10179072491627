import AvailableTeams from '@/components/AvailableTeams'
import { useTopNavStore } from '@/components/TopNav/TopNavContext'
import { NavBarContainer, StaticNavWrapper } from '@/components/TopNav/styles'
import { breakpoints } from '@/styles/breakpoints'
import styled from 'styled-components'

import { HeadingLabel } from '@/styles/fonts'
import { getString } from '@grandstand-web/bally-web-shared/src/newPackages/StringMgmt'

export const NavMainPortalWrapper = styled(StaticNavWrapper)`
  width: 100%;
  background-color: var(--canvas);
  overflow: hidden;
`

const Container = styled(NavBarContainer)`
  padding: var(--mobile-container-spacer);
  ${breakpoints.up('tablet')} {
    padding: var(--tablet-container-spacer);
  }
  ${breakpoints.up('desktop')} {
    padding: var(--container-spacer);
  }
`

const MainSection = styled.div<{ fade: number }>`
  opacity: ${({ fade }) => fade};
  pointer-events: ${({ fade }) => (fade > 0.5 ? 'all' : 'none')};
`
const ContentBar = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoints.up('tablet')} {
    flex-direction: column;
  }
  ${breakpoints.up('desktop')} {
    flex-direction: row;
  }
`

const Title = styled(HeadingLabel)`
  flex: 1 1 auto;
  color: var(--on-background);
`

const NarrowTitle = styled.div`
  ${breakpoints.up('desktop')} {
    max-width: 450px;
  }
`

export const NavMain = () => {
  const { isInMarket, isLoggedIn, fadeOut, availableTeams } = useTopNavStore()
  if (isLoggedIn) return null
  return (
    <Container>
      <MainSection fade={fadeOut}>
        <ContentBar>
          <Title fontSize="8" tabletFontSize="6" mobileFontSize="4" style={{ color: 'var(--on-background)' }}>
            {isInMarket ? (
              <NarrowTitle>{getString('nav.main.header')}</NarrowTitle>
            ) : (
              <>It looks like Bally Sports isn&apos;t available in your area.</>
            )}
          </Title>
          <AvailableTeams teams={availableTeams} />
        </ContentBar>
      </MainSection>
    </Container>
  )
}
