import { HTMLAttributes } from 'react'
import { getString } from '..'

type TSProps = {
  stringKey: string
  as?: keyof JSX.IntrinsicElements
  raw?: boolean
}

const S = ({ stringKey, as = 'div', raw = false }: TSProps) => {
  const Element = as
  const string = getString(stringKey)
  let props: HTMLAttributes<{}> = {}
  raw ? (props.children = string) : (props.dangerouslySetInnerHTML = { __html: string })
  return <Element {...props} />
}

export { S }
