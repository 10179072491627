import { JWTToken } from '@grandstand/presentation-models'
import jwt from 'jsonwebtoken'
import { parseTemplate } from 'url-template'
import { Logger } from '../../utils/logger'

export default interface TokenProvider {
  forceRefresh(): Promise<JWTToken>
  getToken(): Promise<JWTToken>
  logout(): Promise<void>
}

export interface ConvenientApiFetchProps {
  url: string
  method: string
  query?: Record<string, string>
  headers?: Record<string, string>
  body?: object
}

const ApiClientLogger = Logger.of('ApiClient')

let _tokenProvider: TokenProvider

// This is used only in one place as of 2024-05-21: web-watch. It fills in zip_code and entitled_callsigns
// https://middleware.stage.gs.ballysports.com/hgml/08-2020/web-watch{?zip_code}{&entitled_callsigns}
// Also, this is a dumb place to put this
const applyTokenVars = (url: string, token: JWTToken): string => {
  const template = parseTemplate(url)
  const decodedToken = jwt.decode(token, { json: true }) as Record<string, any>
  return template.expand(decodedToken)
}

const stringifyBody = (body: object | undefined) => (typeof body === 'object' ? JSON.stringify(body) : body)

export const ApiClient = {
  logout: () => _tokenProvider.logout(),
  forceRefresh: () => _tokenProvider.forceRefresh(),
  isProviderRegistered: () => !!_tokenProvider,
  registerTokenProvider: async (provider: TokenProvider) => {
    _tokenProvider = provider
  },
  convenientApiFetch: (props: ConvenientApiFetchProps) => {
    const { url, body, ...rest } = props
    return ApiClient.apiFetch(url, {
      ...rest,
      body: stringifyBody(body),
    })
  },
  apiFetch: async (input: RequestInfo | URL, init: RequestInit | undefined) => {
    ApiClientLogger.info('apiFetch', {
      input,
      init,
    })
    const currentToken = await _tokenProvider?.getToken()
    const finalUrl = applyTokenVars(input as string, currentToken)
    init = {
      ...init,
      headers: {
        ...init?.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${currentToken}`,
      },
    }
    ApiClientLogger.info('apiFetch full init:', {
      input,
      finalUrl,
      init,
    })
    let response
    try {
      response = fetch(finalUrl, init)
    } catch (error) {
      ApiClientLogger.info('apiFetch fetch error', { input, finalUrl, init, error })
      throw error
    }
    return response
  },
}
