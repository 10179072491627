import { createGlobalStyle } from 'styled-components'
import { audiowide, national2 } from '../typography'

const GlobalStyles = createGlobalStyle`
    /* Colors, from: https://www.figma.com/file/0ZU7xaRFd8fIgScOaYzPEI/Web-Themes?node-id=1111-2631&t=UapzhRsqee4wFoEO-4 */
    [data-theme="dark"] {
        --canvas: rgba(0,0,0,1);
        --surface: rgba(21,21,21,1);
        --on-background:  rgba(255,255,255,1);
        --on-background-alt:  rgba(142,145,150,1);
        --canvas-light: rgba(243,244,245,1);
        --surface-light:  rgba(255,255,255,1);
        --on-light: rgba(0,0,0,1);
        --on-light-alt: rgba(111,114,116,1);
        --canvas-dark:  rgba(0,0,0,1);
        --surface-dark: rgba(0,0,0,1);
        --on-dark:  rgba(255,255,255,1);
        --on-dark-alt:  rgba(255,255,255,1);
        --tint: rgba(0,114,229,1);
        --tint-hover: rgba(0,92,184,1);
        --tint-pressed: rgba(0,73,146,1);
        --on-tint:  rgba(255,255,255,1);
        --selection:  rgba(255,255,255,1);
        --on-selection: rgba(0,0,0,1);
        --button: rgba(255,255,255,1);
        --button-hover: rgba(255,255,255,0.90);
        --button-pressed: rgba(187,187,187,1);
        --on-button:  rgba(0,0,0,1);
        --button-alt: rgba(255,255,255,0.20);
        --button-alt-hover: rgba(255,255,255,0.20);
        --button-alt-pressed: rgba(255,255,255,0.10);
        --on-button-alt:  rgba(255,255,255,1);
        --button-on-image:  rgba(255,255,255,1);
        --button-on-image-hover:  rgba(255,255,255,0.70);
        --button-on-image-pressed:  rgba(255,255,255,0.90);
        --on-button-on-image: rgba(0,0,0,1);
        --link: rgba(2,104,214,1);
        --divider-canvas: rgba(42,43,45,1);
        --divider-surface:  rgba(42,43,45,1);
        --disabled: rgba(51,51,51,1);
        --error:  rgba(213,0,85,1);
        --success:  rgba(0,135,81,1);
        --ad: rgba(187,253,0,1);
        --success-on-dark: rgba(56,169,124,1);
    }
    [data-theme="light"] {
        --canvas: rgba(243,244,245,1);
        --surface: rgba(255,255,255,1);
        --on-background:  rgba(0,0,0,1);
        --on-background-alt:  rgba(111,114,116,1);
        --canvas-light: rgba(243,244,245,1);
        --surface-light:  rgba(255,255,255,1);
        --on-light: rgba(0,0,0,1);
        --on-light-alt: rgba(111,114,116,1);
        --canvas-dark:  rgba(0,0,0,1);
        --surface-dark: rgba(0,0,0,1);
        --on-dark:  rgba(255,255,255,1);
        --on-dark-alt:  rgba(255,255,255,1);
        --tint: rgba(0,114,229,1);
        --tint-hover: rgba(0,92,184,1);
        --tint-pressed: rgba(0,73,146,1);
        --on-tint:  rgba(255,255,255,1);
        --selection:  rgba(0,0,0,1);
        --on-selection: rgba(255,255,255,1);
        --button: rgba(0,0,0,0.90);
        --button-hover: rgba(0,0,0,1);
        --button-pressed: rgba(69,69,69,1);
        --on-button:  rgba(255,255,255,1);
        --button-alt: rgba(0,0,0,0.20);
        --button-alt-hover: rgba(0,0,0,0.20);
        --button-alt-pressed: rgba(0,0,0,0.10);
        --on-button-alt:  rgba(0,0,0,1);
        --button-on-image:  rgba(255,255,255,1);
        --button-on-image-hover:  rgba(255,255,255,0.70);
        --button-on-image-pressed:  rgba(255,255,255,0.90);
        --on-button-on-image: rgba(0,0,0,1);
        --link: rgba(2,104,214,1);
        --divider-canvas: rgba(236,236,236,1);
        --divider-surface:  rgba(244,244,244,1);
        --disabled: rgba(225,225,225,1);
        --error:  rgba(213,0,85,1);
        --success:  rgba(0,135,81,1);
        --ad: rgba(187,253,0,1);
        --success-on-light: rgba(0,135,81,1);
    }
    :root {
        color-scheme: light dark;

        /* Colors Dark + Light Mode */
        /* Live Colors */
        --live: rgba(236,0,0,1);
        --on-live: rgba(255,255,255,1);

        /* Live Button Colors */
        --button-live: var(--live);
        --button-live-hover: rgba(239, 38, 38,1);
        --button-live-pressed: rgba(165, 0, 0,1);
        --on-button-live: var(--on-live);

        /* Overlay Colors */

        --overlay: rgba(0,0,0,.6);
        --on-overlay: rgba(255,255,255,1);

        /* Text decoration */
        --underline: underline;

        /* Fonts */

        --default-font: ${national2.style.fontFamily};
        --alt-font: ${audiowide.style.fontFamily};

        /* Font Weights */

        --black-heavy: 900;
        --extrabold: 800;
        --bold: 700;
        --medium: 500;
        --regular: 400;
        --light: 350;
        --extra-light: 200;
        --thin: 100;

        --white: rgba(255,255,255,1);

        --video-overlay-bottom: linear-gradient(0deg, rgba(0,0,0,.90) 0%, rgba(0,0,0,0.60) 60%,  rgba(0,0,0,.10) 85%, rgba(0,0,0,0) 95%);
        --video-overlay-top: linear-gradient(180deg,  rgba(0,0,0,.90) 0%, rgba(0,0,0,0.60) 60%,  rgba(0,0,0,.10) 85%, rgba(0,0,0,0) 95%);

        --modal-overlay: rgba(0,0,0,.5);
        /* TODO: this is a gradient
        --overlay-bottom--image:
        */


        /* Heading Font Styles */

        --heading-0-size: 14px;
        --heading-1-size: 16px;
        --heading-2-size: 20px;
        --heading-3-size: 24px;
        --heading-4-size: 32px;
        --heading-5-size: 40px;
        --heading-6-size: 48px;
        --heading-7-size: 56px;
        --heading-8-size: 64px;
        --heading-9-size: 72px;
        --heading-10-size: 80px;

        --heading-line-height: 120%;

        --heading-font-weight: var(--extrabold);

        --heading-font-fam: var(--default-font);


        /* Subheading font Styles */

        --sub-heading-0-size: 14px;
        --sub-heading-1-size: 16px;
        --sub-heading-2-size: 20px;
        --sub-heading-3-size: 24px;
        --sub-heading-4-size: 32px;
        --sub-heading-5-size: 40px;
        --sub-heading-6-size: 48px;
        --sub-heading-7-size: 56px;
        --sub-heading-8-size: 64px;
        --sub-heading-9-size: 72px;
        --sub-heading-10-size: 80px;

        --sub-heading-line-height: 120%;

        --sub-heading-font-weight: var(--light);

        --sub-heading-font-fam: var(--default-font);


        /* WIN font styles */

        --win-0-size: 14px;
        --win-1-size: 16px;
        --win-2-size: 20px;
        --win-3-size: 24px;
        --win-4-size: 32px;
        --win-5-size: 40px;
        --win-6-size: 48px;
        --win-7-size: 56px;
        --win-8-size: 64px;
        --win-9-size: 72px;
        --win-10-size: 80px;

        --win-line-height: 120%;

        --win-font-weight: var(--regular);

        --win-font-fam: var(--alt-font);


        /* Paragraph Font Styles */

        --paragraph-0-size: 14px;
        --paragraph-1-size: 16px;
        --paragraph-2-size: 20px;
        --paragraph-3-size: 24px;
        --paragraph-4-size: 32px;

        --paragraph-line-height: 140%;

        --paragraph-font-weight: var(--regular);

        --paragraph-font-fam: var(--default-font);


        /* Link Font Styles */

        --link-0-size: 12px;
        --link-1-size: 14px;
        --link-2-size: 16px;
        --link-3-size: 20px;
        --link-4-size: 24px;
        --link-5-size: 32px;

        --link-line-height: 140%;

        --link-font-weight: var(--regular);

        --link-font-fam: var(--default-font);


        /* Caption Font Styles */

        --caption-min-size: 10px;
        --caption-xs-size: 12px;
        --caption-sm-size: 14px;
        --caption-md-size: 16px;
        --caption-lg-size: 24px;

        --caption-line-height: 140%;

        --caption-font-weight: var(--regular);

        --caption-font-fam: var(--default-font);


        /* Metadata Font Styles */

        --metadata-min-size: 10px;
        --metadata-xs-size: 12px;
        --metadata-sm-size: 14px;
        --metadata-md-size: 16px;
        --metadata-lg-size: 20px;
        --metadata-xl-size: 24px;
        --metadata-2xl-size: 32px;



        --metadata-line-height: 140%;

        --metadata-font-weight: var(--regular);

        --metadata-font-fam: var(--default-font);


        /* Button Font Styles */
        --button-min-size: 10px;
        --button-xs-size: 12px;
        --button-sm-size: 14px;
        --button-md-size: 16px;
        --button-lg-size: 20px;
        --button-xl-size: 24px;
        --button-2xl-size: 32px;


        --button-line-height: 100%;

        --button-font-weight: var(--bold);

        --button-font-fam: var(--default-font);


        /* Label Primary Font Styles */

        --label-primary-min-size: 10px;
        --label-primary-xs-size: 12px;
        --label-primary-sm-size: 14px;
        --label-primary-md-size: 16px;
        --label-primary-lg-size: 20px;
        --label-primary-xl-size: 24px;
        --label-primary-2xl-size: 32px;

        --label-primary-line-height: 100%;

        --label-primary-font-weight: var(--bold);

        --label-primary-font-fam: var(--default-font);


        /* Label Secondary */

        --label-secondary-min-size: 10px;
        --label-secondary-xs-size: 12px;
        --label-secondary-sm-size: 14px;
        --label-secondary-md-size: 16px;
        --label-secondary-lg-size: 20px;
        --label-secondary-xl-size: 24px;
        --label-secondary-2xl-size: 32px;

        --label-secondary-line-height: 100%;

        --label-secondary-font-weight: var(--regular);

        --label-secondary-font-fam: var(--default-font);


        /* -- Component Sizes -- */

        --wide-default-width: var(--md-wide-width);
        --wide-default-height: var(--md-wide-height);


        /* Container Styles */
        --container-max-width: 1728px;
        /* -- BaseContainer Padding -- */
        --container-spacer: 48px;
        --tablet-container-spacer: 24px;
        --mobile-container-spacer: 16px;
    }
`

export default GlobalStyles
