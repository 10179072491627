import { PackageItemV2 } from '@grandstand/presentation-models'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import TagManager, { TagManagerArgs } from 'react-gtm-module'
import { SelectedPkg } from '../../type/SelectedPkg'
import { getCodeEnvironment, isNotInProd } from '../../utils/envUtils'
import { getWindowDeviceInfo } from '../../utils/getWindowDeviceInfo'
import { UserServiceContext } from '../user/UserService'

declare global {
  interface Window {
    // `dataLayer` is a global array object used by Google Tag Manager (GTM).
    dataLayer: any[]
  }
}

export type GAPackageItem = {
  item_name: string
  item_id: string
  item_brand: string
  item_category: string
  item_variant: string
  quantity: number
  price: number
}
export type GAPackage = {
  package_zipcode: string
  eligibility: string
  package_rsn: string
  subscription_type: string
  package_price: number
  value: number
  message?: string
  ecommerce: {
    value: number
    currency: string
    items: GAPackageItem[]
  }
}

export type AnalyticService = {
  // The Google Tag Manager ID associated with the service
  gtmId?: string

  // (Web: Game Schedule) Records when user select a game schedule in drop-down selector on top screen
  selectSport: (leagueName: string) => Promise<void>

  // (Web: Game Schedule) Tracks a user's click on a score tile.
  clicksOnScoreTile: (params: {
    matchup: string
    home_team: string
    away_team: string
    game_date: string
    league: string
  }) => Promise<void>

  // (Web: Account Sign Up Flow) Records the event of a user attempting to sign up for an account.
  signUpForAccount: (params?: { step?: number; error_message?: string; error_id?: string }) => Promise<void>

  // (Web: Account Sign Up Flow) Records the event of a user attempting to connect their TV provider.
  connectTVProvider: (params: {
    cta: string
    message: string
    error_message: string
    error_id: string
  }) => Promise<void>

  // (Web: Account Sign Up Flow) Tracks the steps a user takes when pairing with a PIN.
  pinPair: (params: { cta: string; step?: number }) => Promise<void>

  // (Web: Account Sign Up Flow) Records an error event that occurred during PIN pairing.
  pinPairError: (params: { step: number; error_message?: string; error_id?: string }) => Promise<void>

  // (Web: Content Engagement Tracking) Records the event of a user selecting their favorite team.
  favoriteTeam: (favorite_team_name: string) => Promise<void>

  // (Web: Content Engagement Tracking) Records the event of a user selecting their favorite league.
  favoriteLeague: (favorite_league_name: string) => Promise<void>

  // (Web: Content Engagement Tracking) Records the event of a user viewing an article.
  articleView: (
    content_name: string,
    content_region: string,
    team: string,
    league: string,
    author: string,
    publish_date: string,
    content_source: string,
    featured: string
  ) => Promise<void>

  // (Web: Settings) Records the event of a user removing all devices from their account.
  removeAllDevices: () => Promise<void>

  // (Web: Settings) Records the event of a user deleting their account.
  deleteAccount: () => Promise<void>

  // (Web: Settings) Records the event of a user pausing their subscription.
  pauseSubscription: (params?: { duration: string }) => Promise<void>

  // (Web: Settings) Records the event of a user resuming their subscription.
  resumeSubscription: () => Promise<void>

  // (Web: Settings) Records the event of a user cancelling their subscription.
  cancelledSubscription: () => Promise<void>
  // (Web: Settings) Records the event of a user upgrading to an annual subscription.

  upgradeAnnualSubscription: () => Promise<void>

  // (Web: Live Video Tracking) Records a user's click on a live video.
  liveUserClicks: (params: {
    content_region: string
    video_title: string
    video_platform: string
    league: string
    home_team: string
    away_team: string
    broadcast_team: string
    video_type: string
    first_air_date: string
    first_air_time: string
    first_air_timestamp: string
    game_id: string
    stream_type: string
    streaming_method: string
  }) => Promise<void>

  // Records the start of a video.
  videoStart: (params: { [key: string]: any }) => Promise<void>

  // Records progress of a video.
  videoProgress: (params: { [key: string]: any; time_played: number; video_consumed: number }) => Promise<void>

  // Records the completion of a video.
  videoComplete: (params: { [key: string]: any }) => Promise<void>

  // (Web: Live Video Tracking) Records a user's click on a short video.
  shotUserClicks: (params: {
    video_title: string
    video_platform: string
    content_region: string
    league: string
    team: string
    video_type: string
    first_air_date: string
    first_air_timestamp: string
    stream_type: string
    material_id: string
    streaming_method: string
  }) => Promise<void>

  // (Web: Couch Rights) Checks if the right coach is active.
  coachRightsActive: () => Promise<void>

  // (Web: Couch Rights) Records an attempt to access content.
  attemptContent: () => Promise<void>

  // (Web: Couch Rights) Records a lockout event.
  lockedOut: () => Promise<void>

  // (Web: Login and Forgot Password) Records a login event.
  login: () => Promise<void>

  // (Web: Login and Forgot Password) Records a logout event.
  logout: () => Promise<void>

  // (Web: Login and Forgot Password) Records a login error.
  loginError: (params: { error_message: string; error_id: string }) => Promise<void>

  // (Web: Login and Forgot Password) Records a password reset attempt.
  forgotPassword: (password_reset_success: boolean) => Promise<void>

  // (Web: Ballys + SignUp DTC) Records a click on the free trial option.
  clickGetBallySportsPlus: (cta: string) => Promise<void>

  // (Web: Ballys + SignUp DTC) Records an invalid zipcode entry.
  invalidZipCode: () => Promise<void>

  // (Web: Ballys + SignUp DTC) Records an out of market event.
  outOfMarket: () => Promise<void>

  // (Web: Ballys + SignUp DTC) Records an upsell interstitial event.
  upsellInterstitial: (params: {
    package_zipcode: string
    ecommerce: {
      items: Array<{
        item_name: string
        item_id: string
        item_brand: string
        item_category: string
        item_variant: string
        quantity: 1
        price: number
      }>
    }
  }) => Promise<void>

  // (Web: Ballys + SignUp DTC) Records the inability to detect a user's location.
  unableToDetectLocation: (error_message: string) => Promise<void>

  // (Web: Ballys + SignUp DTC) Records an event where the user signs in to their TV provider.
  signInTVProvider: (cta: string) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records when packages page loaded.
  viewPackages: (params: GAPackage) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records when user selects a package
  selectPackageItem: (params: GAPackage) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records an event of adding a subscription package to user's cart
  addToCart: (params: {
    value: number
    promotion_name: string
    package_rsn: string
    subscription_type: string
    package_price: number
    package_zipcode: string
    message: string
    ecommerce: {
      value: number
      currency: string
      items: Array<{
        item_name: string
        item_id: string
        item_brand: string
        item_category: string
        item_variant: string
        quantity: number
        coupon: string
        price: number
      }>
    }
  }) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records subscription pakcages when user login
  loginDTC: (params: {
    package_zipcode: string
    step: string
    package_rsn: string
    package_price: number
    subscription_type: string
  }) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records when checkout page loaded.
  beginCheckout: (params: {
    value: number
    coupon: string
    package_zipcode: string
    package_rsn: string
    subscription_type: string
    package_price: number
    ecommerce: {
      currency: string
      value: number
      coupon: string
      items: Array<{
        item_name: string
        item_id: string
        item_brand: string
        item_category: string
        item_variant: string
        quantity: number
        coupon: string
        price: number
      }>
    }
  }) => Promise<void>

  // (Web: Buy DTC Flow - Hard Wall) Records an event when user finalize the purchasing subscriptions
  purchase: (params: {
    coupon: string
    package_zipcode: string
    message: string
    package_rsn: string
    subscription_type: string
    package_price: number
    package_tax: number
    payment_type: string
    value: number
    tax: number
    ecommerce: {
      currency: string
      tax: number
      value: number
      coupon: string
      transaction_id: string
      items: Array<{
        item_name: string
        item_id: string
        item_brand: string
        item_category: string
        item_variant: string
        quantity: number
        coupon: string
        price: number
      }>
    }
  }) => Promise<void>

  // (WebTV: Buy DTC Flow - hard Wall) Records a user's click of button <Get Bally Sports+>
  getBallySports: (params: { cta: string }) => Promise<void>

  // (WebTV: Account Sign Up Flow) Records the event of a user attempting to sign up for an account.
  signUpForAccountWebTV: () => Promise<void>

  // (WebTV: Account Sign Up Flow) Records the event of a user attempting to connect their TV provider.
  connectTVProviderWebTV: (params: { cta: string }) => Promise<void>

  // (WebTV: Video Tracking) Records when user select a different stream
  broadcastEngagementWebTV: (params: {
    broadcast_stream?: string
    video_title?: string
    video_duration?: string
    video_platform?: string
    video_type?: string
    material_id?: string
    league?: string
    content_region?: string
    content_type?: string
    home_team?: string
    away_team?: string
    broadcast_team?: string
    first_air_date?: string
    first_air_time?: string
    first_air_timestamp?: string
    game_id?: string
    game_type?: string
    stream_type?: string
    streaming_method?: string
  }) => Promise<void>

  // (WebTV: Screenview Tracking) Records page views
  pageLoadTrack: (params: {
    page_title: string
    user_id: string
    user_type: string
    device_type?: string
    mvpd_userid?: string
    mvpd_provider?: string
    mvpd_zip?: string
    mvpd_dma?: string
    mvpd_rsn?: string
    auth_userid?: string
    subscription_status?: string
    subscription_rsn?: string
    subscription_zip?: string
  }) => Promise<void>
}

function stubFunction<T extends (...args: any) => any>(): T {
  return (() => {
    throw new Error('Function not implemented.')
  }) as any as T
}

export const AnalyticServiceContext = createContext<AnalyticService>({
  gtmId: undefined,
  selectSport: stubFunction(),
  clicksOnScoreTile: stubFunction(),
  signUpForAccount: stubFunction(),
  connectTVProvider: stubFunction(),
  pinPair: stubFunction(),
  pinPairError: stubFunction(),
  favoriteTeam: stubFunction(),
  favoriteLeague: stubFunction(),
  articleView: stubFunction(),
  coachRightsActive: stubFunction(),
  attemptContent: stubFunction(),
  lockedOut: stubFunction(),
  removeAllDevices: stubFunction(),
  deleteAccount: stubFunction(),
  pauseSubscription: stubFunction(),
  resumeSubscription: stubFunction(),
  cancelledSubscription: stubFunction(),
  upgradeAnnualSubscription: stubFunction(),
  liveUserClicks: stubFunction(),
  videoStart: stubFunction(),
  videoProgress: stubFunction(),
  videoComplete: stubFunction(),
  shotUserClicks: stubFunction(),
  login: stubFunction(),
  logout: stubFunction(),
  loginError: stubFunction(),
  forgotPassword: stubFunction(),
  clickGetBallySportsPlus: stubFunction(),
  invalidZipCode: stubFunction(),
  upsellInterstitial: stubFunction(),
  outOfMarket: stubFunction(),
  unableToDetectLocation: stubFunction(),
  signInTVProvider: stubFunction(),
  viewPackages: stubFunction(),
  selectPackageItem: stubFunction(),
  addToCart: stubFunction(),
  loginDTC: stubFunction(),
  beginCheckout: stubFunction(),
  purchase: stubFunction(),
  getBallySports: stubFunction(),
  signUpForAccountWebTV: stubFunction(),
  connectTVProviderWebTV: stubFunction(),
  broadcastEngagementWebTV: stubFunction(),
  pageLoadTrack: stubFunction(),
})

type AnalyticServiceProviderProps = {
  app: 'web' | 'connected-web'
  gtmId: string
}

export const AnalyticServiceProvider = ({
  app,
  gtmId,
  children,
}: React.PropsWithChildren<AnalyticServiceProviderProps>) => {
  const router = useRouter()
  const userService = useContext(UserServiceContext)
  const [currentGtmId, setCurrentGtmId] = useState<string | undefined>(undefined)
  const codeEnvironment = getCodeEnvironment()
  const debugMode = isNotInProd()
  const { platform } = getWindowDeviceInfo()

  const sendAnalytics = (params: any) => {
    if (typeof window === 'undefined') {
      return
    }
    window.dataLayer = window.dataLayer || []
    const data = {
      code_environment: codeEnvironment,
      device_type: platform,
      page_title: mapPathnameToPageTitle(app, router.asPath.split('?')[0]),
      ...userService.currentUser?.analytics,
      ...params,
    }

    if (debugMode) {
      // GA limitation: the mere presence of the property debug_mode is interpreted as "true", regardless of value
      data.debug_mode = true
    }
    window.dataLayer.push(data)
  }

  const liveService: AnalyticService = {
    gtmId: currentGtmId,
    selectSport: async (league: string): Promise<void> => {
      sendAnalytics({ event: 'score_board', league, action: 'select_sport' })
    },
    clicksOnScoreTile: async (params: {
      matchup: string
      home_team: string
      away_team: string
      game_date: string
      league: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'score_board',
        action: 'select_matchup',
        ...params,
      })
    },
    signUpForAccount: async (params?: { step?: number; error_message?: string; error_id?: string }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        step: 'DTC create account',
        ...params,
      })
    },
    connectTVProvider: async (params: {
      cta: string
      message: string
      error_message: string
      error_id: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        ...params,
      })
    },
    pinPair: async (params: { cta: string; step?: number }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        method: 'pin and pair',
        ...params,
      })
    },
    pinPairError: async (params: { step: number; error_message?: string; error_id?: string }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        method: 'pin and pair',
        ...params,
      })
    },
    favoriteTeam: async (favorite_team_name: string): Promise<void> => {
      sendAnalytics({
        event: 'favorite',
        favorite_team_name,
      })
    },
    favoriteLeague: async (favorite_league_name: string): Promise<void> => {
      sendAnalytics({
        event: 'favorite',
        favorite_league: 1,
        favorite_league_name,
      })
    },
    articleView: async (
      content_name: string,
      content_region: string,
      team: string,
      league: string,
      author: string,
      publish_date: string,
      content_source: string,
      featured: string
    ): Promise<void> => {
      sendAnalytics({
        event: 'dataLayer_loaded',
        content_type: 'article',
        content_name,
        content_region,
        team,
        league,
        author,
        publish_date,
        content_source,
        featured,
      })
    },
    removeAllDevices: async (): Promise<void> => {
      sendAnalytics({
        event: 'account',
        action: 'remove all devices',
      })
    },
    deleteAccount: async (): Promise<void> => {
      sendAnalytics({
        event: 'account',
        action: 'delete account',
      })
    },
    pauseSubscription: async (params?: { duration: string }): Promise<void> => {
      sendAnalytics({
        event: 'account',
        action: 'pause subscription',
        duration: `${params?.duration || '2'} months`,
      })
    },
    resumeSubscription: async (): Promise<void> => {
      // todo: requires patch?
      window.dataLayer.push({
        event: 'account',
        action: 'resume subscription',
      })
    },
    cancelledSubscription: async (): Promise<void> => {
      sendAnalytics({
        event: 'account',
        action: 'subscription canceled',
      })
    },
    upgradeAnnualSubscription: async (): Promise<void> => {
      sendAnalytics({
        event: 'account',
        action: 'upgrade subscription to annual',
      })
    },
    liveUserClicks: async (params: {
      content_region: string
      video_title: string
      video_platform: string
      league: string
      home_team: string
      away_team: string
      broadcast_team: string
      video_type: string
      first_air_date: string
      first_air_time: string
      first_air_timestamp: string
      game_id: string
      stream_type: string
      streaming_method: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'dataLayer_loaded',
        content_type: 'premium video',
        ...params,
      })
    },
    videoStart: async (params: { [key: string]: any }): Promise<void> => {
      sendAnalytics({
        event: 'video_start',
        ...params,
      })
    },
    videoProgress: async (params: {
      [key: string]: any
      time_played: number
      video_consumed: number
    }): Promise<void> => {
      sendAnalytics({
        event: 'video_progress',
        ...params,
      })
    },
    videoComplete: async (params: { [key: string]: any }): Promise<void> => {
      sendAnalytics({
        event: 'video_complete',
        ...params,
      })
    },
    shotUserClicks: async (params: {
      video_title: string
      video_platform: string
      content_region: string
      league: string
      team: string
      video_type: string
      first_air_date: string
      first_air_timestamp: string
      stream_type: string
      material_id: string
      streaming_method: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'dataLayer_loaded',
        content_type: 'free video',
        ...params,
      })
    },
    coachRightsActive: async (): Promise<void> => {
      sendAnalytics({
        event: 'couch_rights',
        message: 'Outside Your Home Zip Code',
        error_id: '010',
      })
    },
    attemptContent: async (): Promise<void> => {
      sendAnalytics({
        event: 'couch_rights',
        message: 'Outside Your Home Zip Code',
        error_id: '011',
      })
    },
    lockedOut: async (): Promise<void> => {
      sendAnalytics({
        event: 'couch_rights',
        message: 'Couch Rights Locked',
        error_id: '012',
      })
    },
    login: async (): Promise<void> => {
      sendAnalytics({
        event: 'login',
        method: 'email',
      })
    },
    logout: async (): Promise<void> => {
      sendAnalytics({
        event: 'account',
        logout: 1,
      })
    },
    loginError: async (params: { error_message: string; error_id: string }): Promise<void> => {
      sendAnalytics({
        event: 'login',
        logout: 1,
        ...params,
      })
    },
    forgotPassword: async (password_reset_success: boolean): Promise<void> => {
      sendAnalytics({
        event: 'login',
        password_reset_success,
      })
    },
    clickGetBallySportsPlus: async (cta: string): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        cta,
      })
    },
    invalidZipCode: async (): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        eligibility: 'out of region',
      })
    },
    upsellInterstitial: async (params: {
      package_zipcode: string
      ecommerce: {
        items: Array<{
          item_name: string
          item_id: string
          item_brand: string
          item_category: string
          item_variant: string
          quantity: 1
          price: number
        }>
      }
    }): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        step: 'upsell interstitial',
        eligibility: 'eligible',
        ...params,
      })
    },
    outOfMarket: async (): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        eligibility: 'out of region',
      })
    },
    unableToDetectLocation: async (error_message): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        eligibility: 'out of region',
        error_message,
      })
    },
    signInTVProvider: async (cta: string): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        cta,
      })
    },
    viewPackages: async (params: GAPackage): Promise<void> => {
      sendAnalytics({
        event: 'view_item',
        currency: 'USD',
        ...params,
      })
    },
    selectPackageItem: async (params: GAPackage): Promise<void> => {
      sendAnalytics({
        event: 'select_item',
        currency: 'USD',
        ...params,
      })
    },
    addToCart: async (params: {
      value: number
      promotion_name: string
      package_rsn: string
      subscription_type: string
      package_price: number
      package_zipcode: string
      message: string
      ecommerce: {
        value: number
        currency: string
        items: Array<{
          item_name: string
          item_id: string
          item_brand: string
          item_category: string
          item_variant: string
          quantity: number
          coupon: string
          price: number
        }>
      }
    }): Promise<void> => {
      sendAnalytics({
        event: 'add_to_cart',
        currency: 'USD',
        eligibility: 'eligible',
        ...params,
      })
    },
    loginDTC: async (params: {
      package_zipcode: string
      step: string
      package_rsn: string
      package_price: number
      subscription_type: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        eligibility: 'eligible',
        package_zipcode: params.package_zipcode,
        step: 'DTC login',
        package_rsn: params.package_rsn,
        package_price: params.package_price,
        subscription_type: params.subscription_type,
      })
    },
    beginCheckout: async (params: {
      value: number
      coupon: string
      package_zipcode: string
      package_rsn: string
      subscription_type: string
      package_price: number
      ecommerce: {
        currency: string
        value: number
        coupon: string
        items: Array<{
          item_name: string
          item_id: string
          item_brand: string
          item_category: string
          item_variant: string
          quantity: number
          coupon: string
          price: number
        }>
      }
    }): Promise<void> => {
      sendAnalytics({
        event: 'begin_checkout',
        eligibility: 'eligible',
        currency: 'USD',
        ...params,
      })
    },
    purchase: async (params: {
      coupon: string
      package_zipcode: string
      message: string
      package_rsn: string
      subscription_type: string
      package_price: number
      package_tax: number
      payment_type: string
      value: number
      tax: number
      ecommerce: {
        currency: string
        tax: number
        value: number
        coupon: string
        transaction_id: string
        items: Array<{
          item_name: string
          item_id: string
          item_brand: string
          item_category: string
          item_variant: string
          quantity: number
          coupon: string
          price: number
        }>
      }
    }): Promise<void> => {
      sendAnalytics({
        event: 'purchase',
        eligibility: 'eligible',
        currency: 'USD',
        ...params,
      })
    },
    getBallySports: async (params: { cta: string }): Promise<void> => {
      sendAnalytics({
        event: 'ballys_plus',
        global_params: {},
        step: 'hard wall',
        ...params,
      })
    },
    signUpForAccountWebTV: async (): Promise<void> => {
      sendAnalytics({
        event: 'dataLayer_loaded',
        page_title: 'sign in',
        global_params: {},
      })
    },
    connectTVProviderWebTV: async (params: { cta: string }): Promise<void> => {
      sendAnalytics({
        event: 'onboarding',
        ...params,
        page_title: 'connect tv provider',
        global_params: {},
      })
    },
    broadcastEngagementWebTV: async (params: {
      broadcast_stream?: string
      video_title?: string
      video_duration?: string
      video_platform?: string
      video_type?: string
      material_id?: string
      league?: string
      content_region?: string
      content_type?: string
      home_team?: string
      away_team?: string
      broadcast_team?: string
      first_air_date?: string
      first_air_time?: string
      first_air_timestamp?: string
      game_id?: string
      game_type?: string
      stream_type?: string
      streaming_method?: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'broadcast_engagement',
        ...params,
      })
    },
    pageLoadTrack: async (params: {
      page_title: string
      user_id: string
      user_type: string
      device_type?: string
      mvpd_userid?: string
      mvpd_provider?: string
      mvpd_zip?: string
      mvpd_dma?: string
      mvpd_rsn?: string
      auth_userid?: string
      subscription_status?: string
      subscription_rsn?: string
      subscription_zip?: string
    }): Promise<void> => {
      sendAnalytics({
        event: 'dataLayer_loaded',
        code_environment: codeEnvironment,
        ...params,
      })
    },
  }
  useEffect(() => {
    if (currentGtmId) {
      return
    }
    const tagManagerArgs: TagManagerArgs = {
      gtmId,
    }
    if (!gtmId || gtmId.length === 0) {
      setCurrentGtmId('--')
    } else {
      TagManager.initialize(tagManagerArgs)
      setCurrentGtmId(gtmId)
    }

    const handlePageView = (app: 'web' | 'connected-web', pathname: string) => {
      if (app == 'connected-web') {
        const ignorePathnames = [
          '/', // ignore index router, as it redirects to home or hardwall
          '/onboarding/signin', // ignore signin, as it handles more granular pageview logic
        ]
        if (ignorePathnames.includes(pathname)) {
          return
        }
      }
      sendAnalytics({
        event: 'dataLayer_loaded',
        page_title: mapPathnameToPageTitle(app, pathname.split('?')[0]), // ensure no query string
      })
    }

    handlePageView(app, router.asPath)

    const handleRouteChangeComplete = (pathname: string) => {
      handlePageView(app, pathname)
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (currentGtmId === undefined) {
    return <></>
  }

  return <AnalyticServiceContext.Provider value={liveService}>{children}</AnalyticServiceContext.Provider>
}

export function mapPathnameToPageTitle(app: 'web' | 'connected-web', pathname: string): string {
  switch (app) {
    case 'web':
      switch (pathname) {
        default:
          return pathname
      }
    case 'connected-web':
      switch (pathname) {
        case '/hardwall':
          return 'Registration Wall'
        default:
          return pathname
      }
  }
}

export const getPromoPriceForPackage = (pkg?: PackageItemV2 | SelectedPkg): number | undefined => {
  if (!pkg) {
    return undefined
  }
  return pkg.promotions?.find((promo) => promo.is_default_promotion && !promo.is_free_trial)?.promotion_price
}

export const getAnalyticsValueForPackage = (pkg: PackageItemV2 | SelectedPkg, isFreeTrial: boolean): number => {
  if (isFreeTrial) {
    return 0
  }
  const promoPrice = getPromoPriceForPackage(pkg)
  if (promoPrice === undefined) {
    return pkg.price
  }
  return promoPrice
}
